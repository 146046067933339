import { MenuItem, Select, FormControl, Checkbox, ListItemText, Box } from "@mui/material";
import { BarChart, ResponsiveContainer, Bar, XAxis, YAxis, Tooltip as RechartsTooltip, Legend as RechartsLegend } from 'recharts';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../Analytics/style.css";
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const BarGraph1 = ({ siteData, Zones, Status, Sizes }) => {
    const sizes = useSelector((state) => state.markerCoordinates.sizes); // Assuming 'tableData' is stored in the Redux store
    const selectAllOptions = process.env.REACT_APP_ALL_SELECT_OPTION
    const isSizeChecked = (size) => selectedSizes.includes(size);

    const [filterData, setFilterData] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState(sizes);




    const CustomArrowIcon = () => (
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill="white" />
        </svg>
    );

    const orderedStatus = [
        'new', // White group
        'New-Assigned', 'ground-inspection', 're-inspection', // Yellow group
        'demolished', 'sealed', 'court-case', // Red group
        'pmay', 'govt-campus', 'not-applicable', 'map-submitted', 'map-issued', // Green group
        'notice', 'do-issued',  // Orange group
    ];


    const FilteredData = () => {
        const zoneSummaryMap = {};

        siteData.forEach((site) => {
            const { Zone, status, size } = site;

            if (selectedSizes.length === 0 || selectedSizes.includes(size)) {
                if (!zoneSummaryMap[Zone]) zoneSummaryMap[Zone] = { zone: Zone };

                if (Status.includes(status)) {
                    if (!zoneSummaryMap[Zone][status]) zoneSummaryMap[Zone][status] = 0;
                    zoneSummaryMap[Zone][status] += 1;
                }
            }
        });

        const sortedZones = Object.values(zoneSummaryMap).sort((a, b) => {
            const zoneA = parseInt(a.zone.split('-')[1]);
            const zoneB = parseInt(b.zone.split('-')[1]);
            return zoneA - zoneB;
        });

        return sortedZones.filter((zoneSummary) => Object.keys(zoneSummary).length > 1);
    };


    const handleSizeChange = (event) => {
        const { value } = event.target;
        if(!value.includes(selectAllOptions) && isSizeChecked(selectAllOptions)){
          setSelectedSizes([""]);
        }
        else if(value.includes(selectAllOptions) && !isSizeChecked(selectAllOptions)){
            setSelectedSizes(sizes);
        }
        else if(value.includes(selectAllOptions) && isSizeChecked(selectAllOptions)){
            setSelectedSizes(value);
        }
        else{
            setSelectedSizes(value);
        }
        
    };

    useEffect(() => {
        const data = FilteredData();
        setFilterData(data);
    }, [siteData, selectedSizes]);

    return (
        <div className="main-Tab">
            <div className="heading-part">
                <h1>Site Status Breakdown by Zone</h1>

                <div className="pieContainer-divider"></div>
                <div className="dropdown">
                    <FormControl
                        required
                        sx={{ m: 1, minWidth: 160 }}
                        style={{
                            backgroundColor: "#2B3D6C",
                            border: "3px solid black",
                            color: "white",
                            display: "flex",
                            alignItems: "center"  // Align items vertically in the center
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FilterAltIcon sx={{ color: "white", marginRight: 1 }} />
                            <Select
                                style={{ color: "white" }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                id="size-select"
                                value={selectedSizes}
                                onChange={handleSizeChange}
                                multiple
                                IconComponent={CustomArrowIcon}
                                renderValue={() => 'Size'}
                            >

                                {sizes.map((size) => (
                                    <MenuItem key={size} value={size}>
                                        <Checkbox checked={isSizeChecked(size)} />
                                        <ListItemText primary={size} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </FormControl>


                </div>
            </div>
            <div className="barGraph">
                <div className="breakdownData" >
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart className="BarChartDiv" data={filterData}>
                            <XAxis dataKey="zone" tick={{ fill: 'black', fontSize: "20px" }} />
                            <YAxis tick={{ fill: 'black', fontSize: "20px" }} />
                            <RechartsTooltip />
                            <RechartsLegend
                                wrapperStyle={{
                                    alignItems: "center",
                                    fontWeight: "600",
                                    color: "black"
                                }}
                            />
                            {orderedStatus.map(status => (
                                <Bar
                                    barGap={20}
                                    barSize={30}
                                    radius={[20, 20, 0, 0]}
                                    key={status}
                                    dataKey={status}
                                    fill={getFillColor(status)}
                                />
                            ))}
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>

        </div>
    );
};


const getFillColor = (status) => {
    switch (status) {
        case 'new':
            return '#E2E2E2';
        case 'New-Assigned':
        case 'new-assigned':
        case 'ground-inspection':
        case 're-inspection':
            return '#F7D000';
        case 'pmay':
        case 'map-issued':
        case 'map-submitted':
        case 'govt-campus':
        case 'not-applicable':
            return 'green';
        case 'notice':
        case 'do-issued':
            return '#c76e00';
        case 'demolished':
        case 'sealed':
        case 'court-case':
            return 'red';
        default:
            return '#ccc'; // Default color for unknown statuses
    }
};

export default BarGraph1;
