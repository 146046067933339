import { createSlice } from "@reduxjs/toolkit";

import helperFunctions from "../../utils/helper";
const selectAllOptions = process.env.REACT_APP_ALL_SELECT_OPTION

const initialState = {
  data: [],
  dataMap: {},
  sizes: [],
  selectedSizes: {},
  deliveryNumbers: [],
  selectedDeliveryNumbers: {},
  type: [selectAllOptions,"Construction", "Plotting"],
  selectedType: {},
  cluster: [],
  selectedCluster: {},
  propertyType: [],
  selectedPropertyType: {},
  loading: false,
  error: null,
};

const mapMarkers = createSlice({
  name: "mapMarkers",
  initialState,
  reducers: {
    fetchMapMarkersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchMapMarkersSuccess(state, action) {
      console.log("fetchDataSuccess map markers success");

      const dataMap = {};
      const payloadData = action.payload;
      console.log("payloadData ", payloadData)
      var distinctSizes = helperFunctions.getDistinctValues(
        payloadData,
        "size"
      );
      distinctSizes.push(selectAllOptions);
      distinctSizes = helperFunctions.sortDistinctSizeValues(distinctSizes)
      const distinctDeliveryNumbers = helperFunctions.getDistinctValues(
        payloadData,
        "Delivery Number"
      );
      distinctDeliveryNumbers.unshift(selectAllOptions);
      const distinctClusters = helperFunctions.getDistinctValues(
        payloadData,
        "cluster"
      )
      var distinctPropertyType = helperFunctions.getDistinctValues(
        payloadData,
        "Land-Property Classification"
      )
       distinctPropertyType = [selectAllOptions, ...distinctPropertyType];
      
      console.log("fetchDataSuccess map markers success distinct delivery numbers");

      payloadData.map((val) => {
        dataMap[val.ID] = val;
      });

      state.loading = false;
      state.sizes = distinctSizes;
      state.selectedSizes = helperFunctions.selectAllValues(distinctSizes);
      state.deliveryNumbers = distinctDeliveryNumbers;
      state.selectedDeliveryNumbers = helperFunctions.selectAllValues(
        distinctDeliveryNumbers
      );
      state.selectedType = helperFunctions.selectAllValues(state.type);
      state.cluster = distinctClusters;
      state.selectedCluster = helperFunctions.unSelectAllValues(state.cluster);
      state.propertyType = distinctPropertyType;
      state.selectedPropertyType = helperFunctions.selectAllValues(distinctPropertyType);
      state.data = action.payload;

      state.dataMap = dataMap;

      console.log("fetchDataSuccess map markers success data");
    },
    selectConstructionPropertyType(state, action){
      state.loading = false;
      state.selectedPropertyType = {
        ...state.selectedPropertyType,
        ...action.payload,
      };
    },
    selectConstructionCluster(state, action){
      state.loading = false;
      state.selectedCluster = {
        ...state.selectedCluster,
        ...action.payload,
      };
    },
    selectConstructionType(state, action){
      state.loading = false;
      state.selectedType = {
        ...state.selectedType,
        ...action.payload,
      };
    },
    selectSiteSize(state, action) {
      state.loading = false;
      state.selectedSizes = {
        ...state.selectedSizes,
        ...action.payload,
      };
    },
    selectDeliveryNumber(state, action) {
      state.loading = false;
      state.selectedDeliveryNumbers = {
        ...state.selectedDeliveryNumbers,
        ...action.payload,
      };
    },
    fetchMapMarkersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateSiteStatus(state, action) {
      console.log("mapMarkers .............Action",action);
      
      const tempData = [];
      state.data.map((val) => {
        const tempVal = val;
        if (val.ID === action.payload.ID) {
          tempVal.status = action.payload.status;
        }
        tempData.push(tempVal);
      });
      state.data = tempData;

      // update data map
      const tempDataMap = {};
      tempData.map((val) => {
        tempDataMap[val.ID] = val;
      });
      state.dataMap = tempDataMap;
      console.log("Status Changed---------",action.payload.ID, " ",state.dataMap[action.payload.ID].status);
      //  console.log(tempData);
      // update in local storage
    //  localStorage.setItem("data", JSON.stringify(tempData));
    },
    updateSiteInfoAttributeInAllSites(state, action) {
      const tempData = [];
      state.data.map((val) => {
        const tempVal = val;
        if (val.ID === action.payload.ID) {
          tempVal[action.payload.attribute] = action.payload.value;
        }
        tempData.push(tempVal);
      });
      state.data = tempData;

      // update data map
      const tempDataMap = {};
      tempData.map((val) => {
        tempDataMap[val.ID] = val;
      });
      state.dataMap = tempDataMap;

      // update in local storage
      try {
        localStorage.setItem("data", JSON.stringify(tempData));
      } catch (e) {
        console.log("Error updating local storage: ");
      }
    }
  },
});

export const {
  fetchMapMarkersStart,
  fetchMapMarkersSuccess,
  fetchMapMarkersFailure,
  selectSiteSize,
  selectDeliveryNumber,
  updateSiteStatus,
  updateSiteInfoAttributeInAllSites,
  selectConstructionType,
  selectConstructionCluster,
  selectConstructionPropertyType,
} = mapMarkers.actions;
export default mapMarkers.reducer;
