import React, { useEffect, useState } from "react";

import "./DashboardComponent.css";
import MapComponent from "../MapComponent/MapComponent";

import { useAuth0 } from "@auth0/auth0-react";
import helperFunctions from "../../utils/helper";
import { Checkbox, Icon } from "@mui/material";

import { PieChart } from "@mui/x-charts";
import { useDispatch, useSelector } from "react-redux";
import Translate from "../Translate/Translate";
import { setApplicationLoading } from "../../redux/reducers/application";
import { manipulateVerticalSidebar } from "../../redux/reducers/verticalSidebarV2";
import { fetchMapMarkersSuccess } from "../../redux/reducers/mapMarkers";
import { fetchZonesSuccess } from "../../redux/reducers/zones";

import pmayLogo from "../../assets/images/pmay-logo.png";

const DashboardComponent = () => {
  const { getAccessTokenSilently } = useAuth0();
  const PIE_CHART_MAPPING = {
    not_started_len: {
      label: "Not Started",
      color: "grey",
      labelInHindi: "शुरू नहीं हुआ",
    },
    ongoing: { label: "Ongoing", color: "green", labelInHindi: "जारी है" },
    completed: { label: "Completed", color: "#EC6547", labelInHindi: "पूर्ण" },
    na: { label: "Not Applicable", color: "white", labelInHindi: "उपलब्ध नहीं" },
  };

  const [dashboardData, setDashboardData] = useState({});
  const [selectedFilter, setSelectedFilter] = useState("ONGOING");
  const [siteStatusWiseDashboard, setSiteStatusWiseDashboard] = useState([
    {
      id: 1,
      siteStatus: "ONGOING",
      label: "Ongoing",
      count: 10,
    },
    {
      id: 2,
      siteStatus: "COMPLETED",
      label: "Completed",
      count: 10,
    },
    {
      id: 3,
      siteStatus: "NA",
      label: "NA/PMAY/Govt.",
      count: 10,
    },
  ]);
  const [pieChartData, setPieChartData] = useState([]);
  const [zoneFilter, setZoneFilter] = useState([]); 

  const [showDropdown, setShowDropdown] = useState(false);
  const [sitesForMap, setSitesForMap] = useState([]);
  const user = useSelector((state) => state.user);

  const zones = useSelector((state) => state.zones);
  const sites = useSelector((state) => state.markerCoordinates);
  const siteStatus = useSelector((state) => state.siteStatus);
  const verticalSidebar = useSelector((state) => state.verticalSidebarV2);

  const dispatch = useDispatch();

  const handleSelectedFilter = (filter) => {
    setSelectedFilter(filter);
  };

  const generatePieChartData = () => {
    const customPieChartData = [];
    
    
    const activeZoneFilter = zoneFilter.includes("all") || zoneFilter.length === 0 
      ? "all" 
      : zoneFilter;
  
    if (dashboardData && dashboardData.pie_chart_dict) {
      const chartData = 
        activeZoneFilter === "all"
          ? dashboardData.pie_chart_dict[activeZoneFilter]
          : activeZoneFilter
              .filter((zone) => dashboardData.pie_chart_dict[zone])
              .reduce((acc, zone) => {
                const zoneData = dashboardData.pie_chart_dict[zone];
                Object.keys(zoneData).forEach((key) => {
                  acc[key] = (acc[key] || 0) + zoneData[key];
                });
                return acc;
              }, {});
  
      if (chartData) {
        Object.keys(chartData).forEach((key) => {
          customPieChartData.push({
            ...PIE_CHART_MAPPING[key],
            id: key,
            value: chartData[key],
            label: user.language === "hi" 
              ? PIE_CHART_MAPPING[key].labelInHindi 
              : PIE_CHART_MAPPING[key].label,
          });
        });
      }
    }
  
    setPieChartData(customPieChartData);
    return customPieChartData;
  };
  
  
  

  const fetchSiteStatusTransactions = async () => {
    // Check local storage for data and then set it to state if present to avoid unnecessary API calls
    const localData = localStorage.getItem("dashboardData");
    if (localData) {
      setDashboardData(JSON.parse(localData));
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    helperFunctions.getSiteStatusTransactions(
      accessToken,
      (res) => {
        setDashboardData(res);
        localStorage.setItem("dashboardData", JSON.stringify(res));
      }
    );
  };

  // show sidebar
  const handleShowSaveReportSidebar = () => {
    dispatch(
      manipulateVerticalSidebar({
        ...verticalSidebar,
        showSidebar: true,
        data: {
          takeInput: true,
          header: "Consolidated Report",
          description: "This is a consolidated report",
          footer: {
            label: "Save Report",
            onClick: (data) => {
              saveReportUsingAPI(data);
            },
          },
        },
      })
    );
  };
  const handleZoneFilter = (zone, event) => {
    if(zone === 'All' && zoneFilter.includes('All')){
      setZoneFilter(['']);
    }
    else{
     
      if (event.target.checked) {
        if(zone === 'All'){
          setZoneFilter(zones.data.map((zones)=>zones.Zone));
        }
        else{
          setZoneFilter((prev) => [...prev, zone]);
        }
      } else {
        setZoneFilter((prev) => prev.filter((item) => item !== zone));
      }
    }
  };
  
  
  
  
  
  

  
  
  
  
  
  

  const saveReportUsingAPI = async (data) => {
    dispatch(setApplicationLoading(true));

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    helperFunctions.saveReportUsingAPI(accessToken, data, (res, err) => {
      if (err) {
        alert("Error in saving report");
        console.log("Something went wrong !");
        dispatch(setApplicationLoading(false));
        return;
      }

      dispatch(
        manipulateVerticalSidebar({
          ...verticalSidebar,
          showSidebar: true,
          data: {
            ...verticalSidebar.data,
            sheetLink: res,
            takeInput: false,
            header: "Consolidated Report",
          },
        })
      );

      dispatch(setApplicationLoading(false));
    });
  };

  const generateListOfSitesForMap = () => {
    const mapMarkers = [];

    dashboardData &&
      dashboardData["recent_ trasactions_dict"] &&
      dashboardData["recent_ trasactions_dict"].map((activity) => {
        if (sites.dataMap[activity.site_id]) {
          mapMarkers.push({
            siteId: activity.site_id,
            ...sites.dataMap[activity.site_id],
          });
        }
      });

    setSitesForMap(mapMarkers);

    return mapMarkers;
  };

  const fetchSites = async (callback) => {
    // check for local storage data
    const sitesLocalData = localStorage.getItem("sitesData");
    if (sitesLocalData) {
      console.log("found sites data in local storage");
      callback(JSON.parse(sitesLocalData));
    } else {
      console.log("no data found in local storage for sites");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url =
      process.env.REACT_APP_API_BASE_URL + `/sites`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in local storage
        localStorage.setItem("sitesData", JSON.stringify(data));

        if (sitesLocalData) {
          return;
        }

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  const fetchZones = async (callback) => {
    // check for local storage data
    const zonesLocalData = localStorage.getItem("zonesData");
    if (zonesLocalData) {
      console.log("found zones data in local storage");
      callback(JSON.parse(zonesLocalData));
    } else {
      console.log("no data found in local storage for zones");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });
    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/get_zones`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in local storage
        localStorage.setItem("zonesData", JSON.stringify(data));
        if (zonesLocalData) {
          return;
        }

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };




  useEffect(() => {
    fetchSiteStatusTransactions();
    generatePieChartData();
   
    fetchSites((data) => {
      fetchZones((zoneData) => {
        dispatch(fetchMapMarkersSuccess(data));
  
        // run the dispatch after 500ms to avoid the error
        setTimeout(() => {
          dispatch(fetchZonesSuccess(zoneData));
        }, 500);
      });
    });
    setZoneFilter(zones.data.map((zones)=>zones.Zone));
  }, []);

  useEffect(() => {
    generatePieChartData();
    generateListOfSitesForMap();
   
  }, [zoneFilter, dashboardData, user]);

  return (
    <div className="dashboard-container">
      {/* Left Side Column */}
      <div className="dashboard-sub-container">
        {/* <div className="map-component-container">
          <MapComponent mapMarkers={sitesForMap} />
        </div> */}

        <div className="dashboard-recent-activities">
          <div className="dashboard-recent-activities-header">
            <h3>
              <Translate text={`Recent Activities`} />
            </h3>
          </div>

          <div className="dashboard-recent-activities-body">
            {dashboardData &&
              dashboardData["recent_ trasactions_dict"] &&
              dashboardData["recent_ trasactions_dict"].map((activity) => (
                <div className="dashboard-recent-activity">
                  <div className="dashboard-recent-activity-type cursor-pointer m-0">
                    {siteStatus?.dataMap[activity["New Status"]]?.type ===
                    "image" ? (
                      <img
                        src={pmayLogo}
                        alt={siteStatus?.dataMap[activity["New Status"]]?.label}
                        style={{
                          width: "3rem",
                          height: "1.5rem",
                        }}
                      />
                    ) : (
                      <Icon
                        title={
                          siteStatus?.dataMap[activity["New Status"]]?.label ||
                          activity["New Status"]
                        }
                        className={
                          "fa-solid " +
                          (siteStatus?.dataMap[activity["New Status"]]?.icon ||
                            "fa-circle")
                        }
                        style={{
                          color:
                            siteStatus?.dataMap[activity["New Status"]]
                              ?.color || "inherit",
                        }}
                        fontSize="small"
                      />
                    )}
                  </div>

                  <div className="dashboard-recent-activity-content-container m-0">
                    <div className="dashboard-recent-activity-content m-0">
                      <Translate
                        text={`Site ${activity.site_id} status has been changed from`}
                      />
                      <b className="badge old-status">
                        <Translate
                          text={
                            siteStatus?.dataMap[activity["Old Status"]]
                              ?.label || activity["Old Status"]
                          }
                        />
                      </b>{" "}
                      ➔{" "}
                      <b className="badge new-status">
                        <Translate
                          text={
                            siteStatus?.dataMap[activity["New Status"]]
                              ?.label || activity["New Status"]
                          }
                        />
                      </b>
                    </div>
                    <div className="dashboard-recent-activity-content-footer m-0">
                      <p className="m-0">
                        <Translate text={activity.Region} />
                      </p>
                      {"•"}
                      <p className="m-0">
                        <Translate
                          text={helperFunctions.formatDate(
                            activity.update_date
                          )}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Right Side Column */}
      <div className="dashboard-sub-container">
        <div className="site-status-wise-dashboard">
          <div className="site-status-wise-dashboard-filter-container">
            {siteStatusWiseDashboard.map((siteStatus) => (
              <div
                className={
                  "site-status-wise-dashboard-filter-button" +
                  (selectedFilter === siteStatus.siteStatus ? " selected" : "")
                }
                onClick={() => handleSelectedFilter(siteStatus.siteStatus)}
              >
                <Translate text={siteStatus.label} />
              </div>
            ))}
          </div>

          <div className="dashboard-recent-activities-body">
            {dashboardData &&
              dashboardData["high_level_status_dict"] &&
              dashboardData["high_level_status_dict"].map(
                (activity) =>
                  ((selectedFilter !== "ONGOING" &&
                    selectedFilter !== "COMPLETED" &&
                    activity.High_Level_status !== "ONGOING" &&
                    activity.High_Level_status !== "COMPLETED") ||
                    activity.High_Level_status === selectedFilter) && (
                    <div className="dashboard-recent-activity">
                      <div className="dashboard-recent-activity-type cursor-pointer m-0">
                        {siteStatus?.dataMap[activity["New Status"]]?.type ===
                        "image" ? (
                          <img
                            src={pmayLogo}
                            alt={
                              siteStatus?.dataMap[activity["New Status"]]?.label
                            }
                            style={{
                              width: "3rem",
                              height: "1.5rem",
                            }}
                          />
                        ) : (
                          <Icon
                            title={
                              siteStatus?.dataMap[activity["New Status"]]?.label ||
                              activity["New Status"]
                            }
                            className={
                              "fa-solid " +
                              (siteStatus?.dataMap[activity["New Status"]]
                                ?.icon || "fa-circle")
                            }
                            style={{
                              color:
                                siteStatus?.dataMap[activity["New Status"]]
                                  ?.color || "inherit",
                            }}
                            fontSize="small"
                          />
                        )}
                      </div>

                      <div className="dashboard-recent-activity-content-container m-0">
                        <div className="dashboard-recent-activity-content m-0">
                          <Translate text={activity.string} />
                        </div>
                        <div className="dashboard-recent-activity-content-footer m-0">
                          <p className="m-0">
                            <Translate text={activity.Region} />
                          </p>
                          {"•"}
                          <p className="m-0">
                            <Translate
                              text={helperFunctions.formatDate(
                                activity.update_date
                              )}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>

        <div className="site-status-wise-dashboard-chart">
          <div className="site-status-wise-dashboard-chart-header m-0">
            <h3 className="m-0">{<Translate text={"Report"} />}</h3>

            <div className="row gap-3">
              <div className="row gap-1" onClick={handleShowSaveReportSidebar}>
                <Icon
                  title="Save Report"
                  baseClassName={"fas"}
                  className={"cursor-pointer fa-file-arrow-down"}
                  fontSize="small"
                />

                <h5 className="m-0 cursor-pointer">
                  <Translate text={"Save Report"} />
                </h5>
              </div>

              <div className="filters-button-container">
                <div className="filters-dropdown-sub-container">
                  <div
                    className="filters-dropdown-selected-label"
                    onClick={() => setShowDropdown(!showDropdown)}
                  >
                    <p>
                      <Translate text={"Zone"} />
                    </p>

                    <Icon
                      title="Filter"
                      baseClassName={"fas"}
                      className={"cursor-pointer fa-caret-down"}
                      fontSize="small"
                    />
                  </div>

                  <div>
                  <div
                      className={
                        "status-select-dropdown zone-filter-dropdown-container" +
                        (showDropdown ? "" : " hide")
                      }
                    >
                      {dashboardData &&
                        dashboardData.pie_chart_dict &&zones.data.map(
                          (zone) =>
                           
                              <div
                                className="site-info-status-select-sub-container cursor-pointer"
                                style={{ color: zone.color }}
                                
                              >
                                <Checkbox
                                  onChange={(evt) =>
                                  {
                           
                                    handleZoneFilter(zone.Zone, evt);
                                  }
                                  }
                                   checked={zoneFilter.includes(zone.Zone)}
                                />

                                <p
                                  className="m-0 filter-label"
                                  style={{ flexGrow: 1 }}
                                >
                                  <Translate text={zone.Zone} />
                                </p>
                              </div>
                            
                        )}
                    </div>
            

                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="site-status-wise-dashboard-chart-body">
            {dashboardData.pie_chart_dict && pieChartData.length > 0 && (
              <PieChart
                series={[
                  {
                    data: pieChartData,
                  },
                ]}
                width={400}
                height={250}
                sx={{
                  [`& .pieArcLabelClasses.root`]: {
                    fill: "white",
                    fontWeight: "bold",
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
