import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

const userMetaData = createSlice({
  name: "userMetaData",
  initialState,
  reducers: {
    fetchUserMetaDataStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchUserMetaDataSuccess(state, action) {
      console.log("User Meta Data Fetched");
      state.loading = false;
      
      const tempData = action.payload;
      state.data = tempData;
      console.log("User Meta Data Fetched successfully");
    },
    fetchUserMetaDataFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
    fetchUserMetaDataStart,
    fetchUserMetaDataSuccess,
    fetchUserMetaDataFailure,
} = userMetaData.actions;
export default userMetaData.reducer;
