import React, { useEffect, useState } from "react";
import { Icon, TextField } from "@mui/material";
import { Checkbox } from "@mui/material";
import "./AddModal.css";
import Translate from "../Translate/Translate";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import helperFunctions from "../../utils/helper";
import { addMembers, updatefieldMember } from "../../redux/reducers/fieldMembers";

function AddModal(props) {
  const { getAccessTokenSilently } = useAuth0();

  const user = useSelector((state) => state.user);
  const fieldConfig = [
    {
      label: "First Name",
      type: "first_name",
      width: "48%",
      minLength: 2,
    },
    {
      label: "Last Name",
      type: "last_name",
      width: "48%",
      minLength: 2,
    },
    {
      label: "Employee ID",
      type: "employee_id",
      width: "48%",
      minLength: 2,
    },
    {
      label: "Phone",
      type: "phone_num",
      width: "48%",
      minLength: 10,
    },
    {
      label: "Zone",
      type: "zone",
      width: "48%",
      minLength: 2,
    },
  ];

  const [formData, setFormData] = useState({});
  const [updateFormData, setUpdateFormData] = useState();
  const [currentZone, setCurrentZone] = useState(null);
  const [showFilter, setShowFilter] = useState(false);

  const zones = useSelector((state) => state.zones);


  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentZone(props.fieldMembersdata['zone']);
    if (props.fieldMembersdata) {
      setUpdateFormData(
        fieldConfig.reduce((acc, field) => {
          acc[field.type] = props.fieldMembersdata[field.type] || "";
          return acc;
        }, {})
      );
    }
  }, [props.fieldMembersdata,]);




  const handleStatusCheckboxClick = (zone) => {
    setCurrentZone(zone);
  };





  const clearUpdatedFormData = () => {
    setUpdateFormData(
      fieldConfig.reduce((acc, field) => {
        acc[field.type] = "";
        return acc;
      }, {})
    );
  };

  const clearFormData = () => {
    setFormData(
      fieldConfig.reduce((acc, field) => {
        acc[field.type] = "";
        return acc;
      }, {})
    );
  };


  const handleUpdateFormDataInputs = (inputType, inputValue) => {
    const obj = {};
    obj[inputType] = inputValue;
    setUpdateFormData({
      ...updateFormData,
      ...obj,
    });
  };
  const handleFormDataInputs = (inputType, inputValue) => {
    const obj = {};
    obj[inputType] = inputValue;
    setFormData({
      ...formData,
      ...obj,
    });
  };





  const createQueryParamString = async () => {
    const promises = fieldConfig.map(async (config) => {
      if (formData[config.type] && formData[config.type] !== "") {
        const formDataValue = await helperFunctions.translateText(
          formData[config.type],
          "en",
          user.language
        );
        return `${config.type}=${formDataValue}`;
      }
      return ""; // Return an empty string for skipped items
    });

    const resolvedValues = await Promise.all(promises);
    const queryParamString = resolvedValues
      .filter((value) => value !== "")
      .join("&");

    return queryParamString;
  };

  const addFieldMemberAPI = async (callback) => {
    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const queryParamString = await createQueryParamString();

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/add_worker?${queryParamString}`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  const updateFieldMemberAPI = async (dataToSend, callback) => {

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url =
      process.env.REACT_APP_API_BASE_URL + `/update_worker`;

    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        employee_id: dataToSend['employee_id'],
        update_fields: {
          ...dataToSend
        }
      })
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Process the retrieved data here   
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong", error);
      });
  };




  const validateFormData = () => {
    let error = true;

    fieldConfig.map((config) => {
      if (
        formData[config.type] === undefined ||
        (formData[config.type] &&
          formData[config.type].length < config.minLength)
      ) {
        alert(
          `There should be minimum ${config.minLength} characters in ${config.label}`
        );
        error = false;
        return false;
      }

      if (config.type === "phone_num" && formData[config.type].length !== 10) {
        alert("Phone number must be exactly 10 digits");
        error = false;
        return false;
      }

    });

    return error;
  };
  const validateUpdateFormData = () => {
    let error = true;

    fieldConfig.map((config) => {
      if (
        updateFormData[config.type] === undefined ||
        (updateFormData[config.type] &&
          updateFormData[config.type].length < config.minLength)
      ) {
        alert(
          `There should be minimum ${config.minLength} characters in ${config.label}`
        );
        error = false;
        return false;
      }

      if (config.type === "phone_num" && updateFormData[config.type].length !== 10) {
        alert("Phone number must be exactly 10 digits");
        error = false;
        return false;
      }
    });

    return error;
  };

  const submitForm = () => {
    if (!validateFormData()) return;

    addFieldMemberAPI((data) => {
      props.setShowModal(false);
      clearFormData();
      dispatch(addMembers(formData));


    });
  };

  const updatesubmitForm = (updateFormData) => {
    if (!validateUpdateFormData()) return;

    updateFieldMemberAPI((updateFormData), data => {
      props.setShowModal(false);
      dispatch(updatefieldMember({
        updateFormData,
      }));
      setShowFilter(!showFilter);
    });
  };






  return (
    props.showModal === true && (
      props.modaltypes === "update" ?
        <>
          <div className="edit-address-modal-container">
            <div className="edit-address-modal add-modal">
              <div className="edit-address-modal-header">
                <h3>{props.modalHeader || "Modal Header"}</h3>

                <div
                  onClick={() => {
                    props.setShowModal(false)
                    clearUpdatedFormData()
                  }}
                  className="close-modal"
                >
                  <Icon
                    title="Close"
                    baseClassName={"fas"}
                    className={"cursor-pointer fa-xmark"}
                    fontSize="small"
                  />
                </div>
              </div>

              <div className="edit-address-form add-modal-form">
                {fieldConfig.map((config) => {
                  return (
                    <div
                      className="edit-address-form-cell-container add-modal-cell-container"
                      style={{
                        width: config.width,
                      }}
                    >
                      <label htmlFor={config.type}>{config.label}</label>

                      <TextField
                        type="text"
                        className="edit-address-input"
                        name={config.type}
                        id={config.type}
                        fullWidth
                        InputProps={{
                          readOnly: config.type === "zone",
                        }}
                        onClick={(evt) => {
                          if (config.type === "zone") {
                            setShowFilter(!showFilter);
                          }
                        }}
                        focused
                        value={
                          updateFormData?.[config.type] || ""
                        }
                        onInput={(evt) => {
                          let inputValue = evt.target.value;

                          if (config.type === "employee_id") {
                            evt.preventDefault();
                            return;
                          }



                          evt.target.value = inputValue;

                          handleUpdateFormDataInputs(config.type, inputValue);
                        }}
                      />

                    </div>
                  );
                })}
              </div>
              {
                showFilter ? <>
                  <div className="selectZone">

                    {zones.data.slice(1).map((zone) => (
                      <div
                        key={zone.Zone}
                        onClick={() => {
                          handleStatusCheckboxClick(zone.Zone)
                          handleUpdateFormDataInputs("zone", zone.Zone);
                        }}
                        className="site-info-status-select-sub-container cursor-pointer"
                        style={{ color: zone.color }}
                      >
                        <Checkbox
                          onChange={() => {
                            handleStatusCheckboxClick(zone.Zone)
                            handleUpdateFormDataInputs("zone", zone.Zone);
                          }}
                          checked={currentZone === zone.Zone}
                        />
                        <p className="m-0" style={{ flexGrow: 1 }}>
                          <Translate text={zone.Zone} />
                        </p>
                      </div>
                    ))}


                  </div>
                </> : <></>
              }



              <div className="save-address-button">
                <button onClick={() => {

                  updatesubmitForm(updateFormData);

                }}>Update Field Member</button>
              </div>
            </div>
          </div>
        </>
        : <div className="edit-address-modal-container">
          <div className="edit-address-modal add-modal">
            <div className="edit-address-modal-header">
              <h3>{props.modalHeader || "Modal Header"}</h3>

              <div
                onClick={() => {
                  clearFormData()
                  props.setShowModal(false)
                }}
                className="close-modal"
              >
                <Icon
                  title="Close"
                  baseClassName={"fas"}
                  className={"cursor-pointer fa-xmark"}
                  fontSize="small"
                />
              </div>
            </div>

            <div className="edit-address-form add-modal-form">
              {fieldConfig.map((config) => {
                return (
                  <div
                    className="edit-address-form-cell-container add-modal-cell-container"
                    style={{
                      width: config.width,
                    }}
                  >
                    <label htmlFor={config.type}>{config.label}</label>

                    <TextField
                      type="text"
                      className="edit-address-input"
                      name={config.type}
                      id={config.type}
                      fullWidth
                      focused
                      InputProps={{
                        readOnly: config.type === "zone",
                      }}
                      onClick={(evt) => {
                        if (config.type === "zone") {
                          setShowFilter(!showFilter);
                        }
                      }}
                      value={formData[config.type] !== null ? formData[config.type] : ""}
                      onInput={(evt) => {
                        let inputValue = evt.target.value;

                        if (config.type === "employee_id" || config.type === "phone") {
                          // Allow only numeric values
                          inputValue = inputValue.replace(/[^0-9]/g, "");
                        }

                        evt.target.value = inputValue;
                        handleFormDataInputs(config.type, inputValue);
                      }}

                    />


                  </div>
                );
              })}
            </div>
            {
              showFilter ? <>
                <div className="selectZone">

                  {zones.data.slice(1).map((zone) => (
                    <div
                      key={zone.Zone}
                      onClick={() => {
                        handleStatusCheckboxClick(zone.Zone)
                        handleFormDataInputs("zone", zone.Zone);
                      }}
                      className="site-info-status-select-sub-container cursor-pointer"
                      style={{ color: zone.color }}
                    >
                      <Checkbox
                        onChange={() => {
                          handleStatusCheckboxClick(zone.Zone)
                          handleFormDataInputs("zone", zone.Zone);
                        }}
                        checked={currentZone === zone.Zone}
                      />
                      <p className="m-0" style={{ flexGrow: 1 }}>
                        <Translate text={zone.Zone} />
                      </p>
                    </div>
                  ))}


                </div>
              </> : <></>
            }
            <div className="save-address-button">
              <button onClick={() => submitForm()}>Add Field Member</button>
            </div>
          </div>
        </div>
    )
  );
}

export default AddModal;
