import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  dataMap: {},
  selected: {},
  loading: false,
  error: null,
};

const fieldMembers = createSlice({
  name: "fieldMembers",
  initialState,
  reducers: {
    fetchFieldMembersStart(state) {
      state.loading = true;
      state.error = null;
    },
    fetchFieldMembersSuccess(state, action) {
      console.log("fetchDataSuccess zones success");
      
      
      state.loading = false;

      const tempData = action.payload;
      const tempDataMap = {};

      for (let i = 0; i < tempData.length; i++) {

        tempDataMap[tempData[i].employee_id] = tempData[i];

      }


      state.data = tempData;
      state.dataMap = tempDataMap;

      console.log("fetchDataSuccess zones success data");
    },
    addMembers(state, action) {
      state.loading = false;
      state.error = null;

      const empData = action.payload;

      if (!empData) {
        console.error("formData is undefined in action.payload");
        return;
      }

      state.data.push(empData);
    },

    updatefieldMember(state, action) {

      const { employee_id, ...updateDetails } = action.payload.updateFormData;


      const updatedData = [];

      state.loading = false;
      state.error = null;


      if (state.dataMap[employee_id]) {
        for (const key in updateDetails) {
          if (updateDetails[key] !== undefined) {
            state.dataMap[employee_id][key] = updateDetails[key];
          }

        };
        for (const key in state.dataMap) {
          if (state.dataMap[key]) {
            updatedData.push(state.dataMap[key]);
          }
        }

        state.data = updatedData;
        console.log(`Employee successfully updated.`);
      } else {
        console.error(`No Employee Found with ID: ${employee_id}`);
      }

    },


    deleteFieldMembers(state, action) {
      state.loading = false;
      state.error = null;
      const updatedList = [];
      if (state.dataMap[action.payload]) {
        delete state.dataMap[action.payload];

        for (const key in state.dataMap) {
          if (state.dataMap[key]) {
            updatedList.push(state.dataMap[key]);
          }
        }

        state.data = updatedList;
      }
    },


    selectFieldMembers(state, action) {
      state.loading = false;
      state.selected = {
        ...state.selected,
        ...action.payload,
      };
    },
    fetchFieldMembersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    resetFieldMembers(state) {
      state.data = [];
      state.dataMap = {};
      state.selected = {};
      state.loading = false;
      state.error = null;
    }
  },
});

export const {
  fetchFieldMembersStart,
  fetchFieldMembersSuccess,
  addMembers,
  selectFieldMembers,
  fetchFieldMembersFailure,
  resetFieldMembers,
  updatefieldMember,
  deleteFieldMembers
} = fieldMembers.actions;
export default fieldMembers.reducer;
