import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./VerticalSidebar.css";
import {
  resetVerticalSidebar,
  setSidebarVisibility,
} from "../../redux/reducers/verticalSidebarV2";
import { Checkbox, Icon, TextField } from "@mui/material";
import Translate from "../Translate/Translate";

const VerticalSidebar = (props) => {
  const verticalSidebar = useSelector((state) => state.verticalSidebarV2);
  const deliveryNumbers = useSelector(
    (state) => state.markerCoordinates.deliveryNumbers
  );
  const sizes = useSelector(
    (state) => state.markerCoordinates.sizes
  );
  const zones = useSelector((state) => state.zones);
  const user = useSelector((state) => state.user);

  const [selectedFilters, setSelectedFilters] = useState({
    deliveryNumber: ["all"],
    zone: ["all"],
    size: ["all"],
    emailId: "",
  });

  const dispatch = useDispatch();

  const [showFilters, setShowFilters] = useState({
    deliveryNumber: false,
    zone: false,
    size: false,
  });

  const toggleFilters = (filter) => {
    setShowFilters({
      ...showFilters,
      deliveryNumber: false,
      zone: false,
      size: false,
      [filter]: !showFilters[filter],
    });
  };

  const handleCloseVerticalSidebar = () => {
    dispatch(resetVerticalSidebar());
  };

  const handleDeliveryNumberCheckboxClick = (deliveryNumber) => {
    setSelectedFilters({
      ...selectedFilters,
      deliveryNumber: [deliveryNumber],
    });
  };

  const handleSizeCheckboxClick = (size) => {
    setSelectedFilters({
      ...selectedFilters,
      size: [size],
    });
  };

  const handleZoneCheckboxClick = (zone) => {
    setSelectedFilters({
      ...selectedFilters,
      zone: [zone],
    });
  };

  const emailValidation = (emails) => {
    const emailRegex =
      /^[\s]*([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(,\s*([a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}))*[\s]*$/;

    if (!emailRegex.test(emails)) {
      alert(
        "Please enter valid email addresses separated by commas without spaces around commas."
      );
      return false;
    }

    return true;
  };

  const handleEmailIdChange = (evt) => {
    setSelectedFilters({
      ...selectedFilters,
      emailId: evt.target.value,
    });
  };

  return (
    verticalSidebar.showSidebar == true && (
      <div className="vertical-sidebar-main-container site-details-sidebar-container">
        {verticalSidebar.data.header && (
          <div className="vertical-sidebar-header-container">
            <div className="vertical-sidebar-header">
              <h3>
                <Translate text={verticalSidebar.data.header} />
              </h3>
            </div>

            <div
              onClick={() => handleCloseVerticalSidebar()}
              className="close-modal"
            >
              <Icon
                title="Close"
                baseClassName={"fas"}
                className={"cursor-pointer fa-xmark"}
                fontSize="small"
              />
            </div>
          </div>
        )}

        {verticalSidebar.data.sheetLink && (
          <div className="link-box-container">
            <div id="real-link-box">{verticalSidebar.data.sheetLink}</div>
            <div
              id="copy-path-traversal-link-btn"
              onClick={() => {
                window.open(verticalSidebar.data.sheetLink, "_blank");
              }}
            >
              <Translate text="Open link" />
            </div>
          </div>
        )}

        {verticalSidebar.data.takeInput && (
          <>
            <div className="vertical-sidebar-body-container">
              <div className="vertical-sidebar-body-box">
                <h4>
                  <Translate text="Delivery Number" />
                </h4>

                {
                  <div
                    className="site-info-status-select-sub-container select-label-selected-option cursor-pointer"
                    onClick={() => {
                      toggleFilters("deliveryNumber");
                    }}
                  >
                    <p className="m-0" style={{ flexGrow: 1 }}>
                      {selectedFilters.deliveryNumber.length > 0 && (
                        <Translate text={selectedFilters.deliveryNumber[0]} />
                      )}
                    </p>

                    <Icon
                      title="Toggle"
                      baseClassName={"fas"}
                      className={
                        "cursor-pointer fa-chevron-" +
                        (showFilters.deliveryNumber ? "up" : "down")
                      }
                      fontSize="small"
                    />
                  </div>
                }

                {showFilters.deliveryNumber && (
                  <div
                    className="status-select-dropdown"
                    style={{
                      overflowY: "scroll",
                      width: "15rem",
                      maxHeight: "15rem",
                    }}
                  >
                    {["all", ...deliveryNumbers.filter((deliveryNumber) => deliveryNumber !== "All")].map((deliveryNumber) => {
                      return (
                        <div
                          key={deliveryNumber}
                          className="site-info-status-select-sub-container cursor-pointer"
                          onClick={() => {
                            handleDeliveryNumberCheckboxClick(deliveryNumber);
                          }}
                        >
                          <Checkbox
                            onChange={() =>
                              handleDeliveryNumberCheckboxClick(deliveryNumber)
                            }
                            checked={
                              deliveryNumber ===
                              selectedFilters.deliveryNumber[0]
                            }
                          />

                          <p className="m-0" style={{ flexGrow: 1 }}>
                            <Translate text={deliveryNumber} />
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="vertical-sidebar-body-container">
              <div className="vertical-sidebar-body-box">
                <h4>
                  <Translate text="Size" />
                </h4>

                {
                  <div
                    className="site-info-status-select-sub-container select-label-selected-option cursor-pointer"
                    onClick={() => {
                      toggleFilters("size");
                    }}
                  >
                    <p className="m-0" style={{ flexGrow: 1 }}>
                      {selectedFilters.size.length > 0 && (
                        <Translate text={selectedFilters.size[0]} />
                      )}
                    </p>

                    <Icon
                      title="Toggle"
                      baseClassName={"fas"}
                      className={
                        "cursor-pointer fa-chevron-" +
                        (showFilters.size ? "up" : "down")
                      }
                      fontSize="small"
                    />
                  </div>
                }

                {showFilters.size && (
                  <div
                    className="status-select-dropdown"
                    style={{
                      overflowY: "scroll",
                      width: "15rem",
                      maxHeight: "15rem",
                    }}
                  >
                    {["all", ...sizes.filter((size) => size != "All")].map((size) => {
                      return (
                        <div
                          key={size}
                          className="site-info-status-select-sub-container cursor-pointer"
                          onClick={() => {
                            handleSizeCheckboxClick(size);
                          }}
                        >
                          <Checkbox
                            onChange={() =>
                              handleSizeCheckboxClick(size)
                            }
                            checked={
                              size ===
                              selectedFilters.size[0]
                            }
                          />

                          <p className="m-0" style={{ flexGrow: 1 }}>
                            <Translate text={size} />
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            <div className="vertical-sidebar-body-container">
              <div className="vertical-sidebar-body-box">
                <h4>
                  <Translate text="Zone" />
                </h4>

                {
                  <div
                    className="site-info-status-select-sub-container select-label-selected-option cursor-pointer"
                    onClick={() => {
                      toggleFilters("zone");
                    }}
                  >
                    <p className="m-0" style={{ flexGrow: 1 }}>
                      {selectedFilters.zone.length > 0 && (
                        <Translate text={selectedFilters.zone[0]} />
                      )}
                    </p>

                    <Icon
                      title="Toggle"
                      baseClassName={"fas"}
                      className={
                        "cursor-pointer fa-chevron-" +
                        (showFilters.zone ? "up" : "down")
                      }
                      fontSize="small"
                    />
                  </div>
                }

                {showFilters.zone && (
                  <div
                    className="status-select-dropdown"
                    style={{
                      overflowY: "scroll",
                      width: "15rem",
                      maxHeight: "15rem",
                    }}
                  >
                    {[{ Zone: "all" }, ...zones.data.filter((zone => zone.Zone != "All"))].map((zone) => {
                      return (
                        <div
                          key={zone.Zone}
                          className="site-info-status-select-sub-container cursor-pointer"
                          style={{ color: zone.color }}
                          onClick={() => {
                            handleZoneCheckboxClick(zone.Zone);
                          }}
                        >
                          <Checkbox
                            onChange={() => handleZoneCheckboxClick(zone.Zone)}
                            checked={zone.Zone === selectedFilters.zone[0]}
                          />

                          <p className="m-0" style={{ flexGrow: 1 }}>
                            <Translate text={zone.Zone} />
                          </p>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {/* Input div for email id */}
            <div className="vertical-sidebar-body-container">
              <div className="vertical-sidebar-body-box">
                <h4>
                  <Translate text="Email ID" />
                </h4>

                <div
                  className={"search-locations-container enter-email-container"}
                >
                  <TextField
                    id="enter-email-list"
                    placeholder={
                      user.language !== "hi"
                        ? "Enter Email ID"
                        : "ईमेल आईडी दर्ज करें"
                    }
                    type="search"
                    fullWidth
                    focused
                    onInput={(evt) => {
                      handleEmailIdChange(evt);
                    }}
                    style={{ width: "20rem", boxSizing: "border-box" }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {verticalSidebar.data.footer && (
          <div
            className="vertical-sidebar-footer cursor-pointer"
            onClick={() => {
              if (!emailValidation(selectedFilters.emailId)) {
                return;
              }
              verticalSidebar.data.footer.onClick({
                delivery_number: selectedFilters.deliveryNumber[0],
                zone: selectedFilters.zone[0],
                size: selectedFilters.size[0],
                email_id: selectedFilters.emailId,
              });
            }}
          >
            {verticalSidebar.data.footer.label}
          </div>
        )}
      </div>
    )
  );
};

export default VerticalSidebar;
