import "./App.css";
import MapContainer from "./components/MapContainer/MapContainer";
import MapTopbar from "./components/MapTopbar/MapTopbar";
import Sidebar from "./components/Sidebar/Sidebar";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TableComponent from "./components/Table/Table";
import TablePath from "./components/Table/TablePath";
import SiteDetailsSidebar from "./components/SidebarPathTraversal/SidebarPathTraversal";
import GeotricsFooter from "./components/GeotricsFooter/GeotricsFooter";
import LoginForm from "./components/Login/Login";

import { useAuth0 } from "@auth0/auth0-react";
import Loader from "./components/Loader/Loader";
import Payment from "./components/Payment/Payment";
import Dashboard from "./pages/Dashboard/Dashboard";

import { useTheme } from "./ThemeContext";
import DottedCircleLoader from "./components/Loader/DottedCircleLoader";
import VerticalSidebar from "./components/VerticalSidebar/VerticalSidebar";
import UploadImageBtn from "./components/UploadImage/Button/UploadImageBtn";
import UploadImageSidebar from "./components/UploadImage/UploadImageSidebar";
import EmptyStateLoader from "./components/Modal/EmptyStateLoader";
import Modal from "./components/Modal/Modal";
import MapView from "./components/MapContainer/MapViews/MapView"
import CardWithDoughnut from "./components/Analytics/CardWithDoughnut";
function Child() {
  const [searchSiteid, setSearchSiteid] = useState(false);
  const [viewSection, setViewSection] = useState("map");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoadingTimeoutComplete, setIsLoadingTimeoutComplete] =
    useState(false);

  const verticalSidebar = useSelector((state) => state.verticalSidebarV2);

  const { user, isAuthenticated, isLoading } = useAuth0();

  const { theme, toggleTheme } = useTheme();
  const application = useSelector((state) => state.application);

  const handleSidebarClick = (toggleSectionType) => {
    setViewSection(toggleSectionType);
  };

  const selectedClusters = useSelector(
    (state) => state.markerCoordinates.selectedCluster
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoadingTimeoutComplete(true);
    }, 2000); // Delay in milliseconds (5 seconds)

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    let allFalse = true;
    for (const value of Object.values(selectedClusters)) {
      if (value === true) {
        allFalse = false;
        break;
      }
    }
    if(allFalse)
      console.log("Cluster selected")
    setIsModalOpen(allFalse);
  }, [selectedClusters]);

  if (isLoading || !isLoadingTimeoutComplete) {
    return <Loader />;
  }
  return (
    <div className={theme + (application.loading ? " inactive" : "")}>
      {application.loading && <DottedCircleLoader isFullPage={true} />}

      {isAuthenticated ? (
        <div style={{ display: "flex" }} className="master-container">
          <Sidebar
            handleSidebarClick={handleSidebarClick}
            viewSection={viewSection}
          />

          {application.showImageBox && <UploadImageSidebar />}

          {verticalSidebar.showSidebar && <VerticalSidebar />}

          {viewSection === "map" ? (
            <>
              <MapContainer searchSiteid={searchSiteid} />
              <MapTopbar setSearchSiteid={setSearchSiteid} />
              <SiteDetailsSidebar />
              {/* <UploadImageBtn /> */}
              <MapView></MapView>
              <GeotricsFooter />
            </>
          ) : viewSection === "sites-table" ? (
//            <TableComponent />
              isModalOpen ? (
                <Modal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false); setViewSection("map"); }}>
                  <EmptyStateLoader message="Please select at least one cluster !"/>
                </Modal>
              ) : (
                <TableComponent />
                )
          ) : viewSection === "saved-paths-table" ? (
              <TablePath />
          ) : viewSection === "dashboard" ? (
            <Dashboard />
          ) : viewSection === "analytics" ? (
            <CardWithDoughnut />
          ): (
            <Payment />
          )}
        </div>
      ) : (
        <LoginForm />
      )}
    </div>
  );
}

export default Child;
