import mapboxgl from "mapbox-gl"; // This is a dependency of react-map-gl even if you didn't explicitly install it

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import ReactMapGL, { Marker, Popup, Source, Layer } from "react-map-gl";
import { Icon } from "@mui/material";

import "mapbox-gl/dist/mapbox-gl.css";

import MarkerPopup from "../MarkerPopup/MarkerPopup";


import "./MapContainer.css";

import { fetchMapMarkersSuccess, selectConstructionCluster } from "../../redux/reducers/mapMarkers";
import { fetchClustersSuccess } from "../../redux/reducers/clusters";
import { fetchZonesSuccess, fetchAoiSuccess, selectZones } from "../../redux/reducers/zones";
import {
  setSidebarType,
  setSiteImage,
} from "../../redux/reducers/selectedSitesSidebar";
import { useAuth0 } from "@auth0/auth0-react";

import pmayLogo from "../../assets/images/pmay-logo.png";
import constructiongif1 from '../../assets/construction2.gif';
import { fetchUserMetaDataSuccess } from "../../redux/reducers/userMetaData";
import { setVisibleSiteCount } from "../../redux/reducers/MapView";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN; 

function MapContainer(props) {
  const defaultViewPort = {
    latitude: 28.836927000000003,
    longitude: 78.79018300000001,
    zoom: 12,
  };
  const [viewPort, setViewport] = useState(defaultViewPort);
  const [showPopup, setShowPopup] = useState(false);
  const siteInfo = useSelector(
    (state) => state.verticalSidebar.currentSelectedSite
  );
  const siteImage = useSelector((state) => state.verticalSidebar.siteImage);
  const [visibleSitesCount,setVisibleSitesCount] = useState(0);
  const markerCoordinates = useSelector(
    (state) => state.markerCoordinates.data
  );
  const clustersCoordinates = useSelector(
    (state) => state.clusterCoordinates.data
  )
  // console.log("clustersCoordinates ", clustersCoordinates)
  // console.log(".....markerCoordinates.....", markerCoordinates)
  const sizes = useSelector((state) => state.markerCoordinates.sizes);
  const selectedSizes = useSelector(
    (state) => state.markerCoordinates.selectedSizes
  );
  const selectedDeliveryNumbers = useSelector(
    (state) => state.markerCoordinates.selectedDeliveryNumbers
  );
  const selectedConstructionType = useSelector(
    (state) => state.markerCoordinates.selectedType
  )
  const selectedClusters = useSelector(
    (state) => state.markerCoordinates.selectedCluster
  )
  //console.log("selectedClusters ", selectedClusters)
  const changeMap = useSelector(
    (state) => state.mapViews
  ) || "";
 
  const selectedProperty = useSelector((state) => state.markerCoordinates.selectedPropertyType)

  const searchSiteid = useSelector((state) => state.searchSiteid);
  const zones = useSelector((state) => state.zones);
  const siteStatus = useSelector((state) => state.siteStatus);
  const selectedMarker = useSelector((state) => state.verticalSidebar.selectedSites);
  const verticalSidebar = useSelector((state) => state.verticalSidebar);

  const dispatch = useDispatch();
  
   
  const handleMarkerClick = (evt, mapMarker) => {
    // deep copy the selectedMarker object

    const newSelectedMarker = JSON.parse(JSON.stringify(mapMarker));
    console.log("New Selector Data",newSelectedMarker);
    setShowPopup(true);
    handleSidebarTypeChange(newSelectedMarker, null);
    dispatch(setSiteImage(null));

    try {
      fetchSiteImage(newSelectedMarker.ID, (data) => {
        handleSidebarTypeChange(newSelectedMarker, data);
      });
    } catch (error) {
      console.error("Error: Something went wrong");
    }
  };

  const handleClusterClick = (siteData) => {
    if (!siteData.cluster) {
      console.warn("No cluster data found for the site.");
      return;
    }
    console.log("AOIs ", zones.aois)
    //  console.log("selectedClusters ", selectedClusters[cluster.cluster])
    const clusterSelectedObj = {};
    clusterSelectedObj[siteData.cluster] = !selectedClusters[siteData.cluster];

    

    dispatch(selectConstructionCluster(clusterSelectedObj))

  }

  const handleSearchSiteIDClusterClick = (siteData) => {
    if (!siteData.cluster) {
      console.warn("No cluster data found for the site.");
      return;
    }
    // console.log("selectedClusters ", selectedClusters[cluster.cluster])
    const clusterSelectedObj = {};
    clusterSelectedObj[siteData.cluster] = true;

    dispatch(selectConstructionCluster(clusterSelectedObj))

  }

  const findSearchSiteid = (siteid) => {
    siteid = parseInt(siteid);
    // console.log("siteid ", siteid, markerCoordinates);
    for (let i = 0; i < markerCoordinates.length; i++) {
      // console.log(siteid, markerCoordinates[i]);
      if (markerCoordinates[i].ID === siteid) {
        return markerCoordinates[i];
      }
    }
    return null; // Return null if the object with the desired ID is not found
  };

  function calculateCentroid(coords) {
    // console.log("coords", coords);
    const numCoords = coords.length;

    // Calculate the sum of latitudes and longitudes
    let sumLat = 0;
    let sumLong = 0;

    coords.forEach((coord) => {
      sumLat += coord.Latitude;
      sumLong += coord.Longitude;
    });

    // Calculate the average latitudes and longitudes
    const avgLat = sumLat / numCoords;
    const avgLong = sumLong / numCoords;

    return { latitude: avgLat, longitude: avgLong };
  }

  const handleSidebarTypeChange = (currentSiteInfo, siteImage) => {
    const currentSiteInfoCopy = JSON.parse(JSON.stringify(currentSiteInfo));

    dispatch(
      setSidebarType({
        sidebarType:
          verticalSidebar.sidebarType !== "siteProgress" &&
          verticalSidebar.sidebarType !== "siteInfo"
            ? "pathTraversal"
            : currentSiteInfoCopy.status !== "new"
            ? "siteProgress"
            : "siteInfo",
        siteInfo: currentSiteInfoCopy,
        siteImage: siteImage,
      })
    );

    // dispatch(setSiteImage(siteImage));
  };

  const { user, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const fetchSites = async (callback) => {
    // check for local storage data
    const sitesLocalData = localStorage.getItem("sitesData");
    if (sitesLocalData) {
      console.log("found sites data in local storage");
      callback(JSON.parse(sitesLocalData));
    } else {
      console.log("no data found in local storage for sites");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url = process.env.REACT_APP_API_BASE_URL + `/sites`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in local storage
        localStorage.setItem("sitesData", JSON.stringify(data));

        if (sitesLocalData) {
          return;
        }

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  const fetchClusters = async (callback) => {
    // check for local storage data
    const clustersLocalData = localStorage.getItem("clustersData");
    if (clustersLocalData) {
      console.log("found clusters data in local storage");
      callback(JSON.parse(clustersLocalData));
    } else {
      console.log("no data found in local storage for clusters");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url = process.env.REACT_APP_API_BASE_URL + `/hotspots`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in local storage
        localStorage.setItem("clustersData", JSON.stringify(data));

        if (clustersLocalData) {
          return;
        }

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  const fetchAois = async (callback) => {
    // check for local storage data
    const aoisLocalData = localStorage.getItem("aoisData");
    if (aoisLocalData) {
      console.log("found AOIs data in local storage");
      callback(JSON.parse(aoisLocalData));
    } else {
      console.log("no data found in local storage for AOIs");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url = process.env.REACT_APP_API_BASE_URL + `/get_aoi`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in local storage
        localStorage.setItem("aoisData", JSON.stringify(data));

        if (aoisLocalData) {
          return;
        }

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };


  const fetchZones = async (callback) => {
    // check for local storage data
    const zonesLocalData = localStorage.getItem("zonesData");
    if (zonesLocalData) {
      console.log("found zones data in local storage");
      callback(JSON.parse(zonesLocalData));
    } else {
      console.log("no data found in local storage for zones");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });
    const url = process.env.REACT_APP_API_BASE_URL + `/get_zones`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in local storage
        localStorage.setItem("zonesData", JSON.stringify(data));
        if (zonesLocalData) {
          return;
        }

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  const fetchSiteImage = async (siteID, callback) => {
    // check for local storage data with key as siteImage and value as {siteID: siteImage}
    const siteImageLocalData = localStorage.getItem("siteImage")
      ? JSON.parse(localStorage.getItem("siteImage"))
      : {};
    if (siteImageLocalData && siteImageLocalData[siteID]) {
      console.log("found site image data in local storage");
      callback(siteImageLocalData[siteID]);
    } else {
      console.log("no data found in local storage for site image");
    }

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });

    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/get_site_imagery?site_id=${siteID}`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Set the data in local storage
        siteImageLocalData[siteID] = data;
        // localStorage.setItem("siteImage", JSON.stringify(siteImageLocalData));

        // Process the retrieved data here
        callback(data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error: Something went wrong");
      });
  };

  const fetchMetaData = async (callback) => {
    

    const accessToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      },
    });
    const url =
      process.env.REACT_APP_API_BASE_URL +
      `/fetch_user_meta_data`;
    const authToken = accessToken; // Replace with your actual authorization token

    fetch(url, {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
         callback(data);
      })
      .catch((error) => {
       
        console.error("Error: Something went wrong");
      });
  };

  const setDefaultViewPort = (filteredData) => {
    const currCentroid = calculateCentroid(filteredData);
    // console.log("currCentroid", currCentroid);
    if (currCentroid.longitude && currCentroid.latitude) {
      setViewport({
        ...viewPort,
        longitude: currCentroid.longitude,
        latitude: currCentroid.latitude,
        zoom: defaultViewPort.zoom,
      });
    } else {
      setViewport(viewPort);
    }
  };

  useEffect(() => {

       fetchSites((data) => {
       fetchZones((zoneData) => {
        dispatch(fetchMapMarkersSuccess(data));
        
        // run the dispatch after 500ms to avoid the error
        setTimeout(() => {
          dispatch(fetchZonesSuccess(zoneData));
        }, 500);
      });
    });
    fetchClusters((data) => { dispatch(fetchClustersSuccess(data))});
    fetchMetaData((data)=>{dispatch(fetchUserMetaDataSuccess(data))});
    fetchAois((data) => {dispatch(fetchAoiSuccess(data))});
  }, []);

  useEffect(() => {
    if (searchSiteid && searchSiteid.data !== "") {
      console.log("valid search site id");

      const searchedSite = findSearchSiteid(searchSiteid.data);
      console.log("searchedSite ", searchedSite);
      if (searchedSite) {
        handleSearchSiteIDClusterClick(searchedSite)
        setViewport({
          ...viewPort,
          longitude: searchedSite.Longitude,
          latitude: searchedSite.Latitude,
          zoom: 20,
        });
      } else {
        setDefaultViewPort(markerCoordinates);
      }
    } else if (searchSiteid === "") {
      setDefaultViewPort(markerCoordinates);
    }
  }, [searchSiteid]);

  useEffect(() => {
    const filteredData = markerCoordinates.filter(
      (item) => zones.selected[item.Zone] === true
    );

    setDefaultViewPort(filteredData);
  }, [zones]);


  useEffect(() => {
    if (markerCoordinates && markerCoordinates.length > 0) {
      // Calculate the count of visible markers
      const count = markerCoordinates.reduce((acc, mapMarker) => {
        if (
          zones.selected[mapMarker.Zone] &&
          selectedSizes[mapMarker.size] &&
          selectedDeliveryNumbers[mapMarker["Delivery Number"]] &&
          siteStatus.selected[mapMarker.status] &&
          selectedClusters[mapMarker.cluster] &&
          selectedProperty[mapMarker["Land-Property Classification"]] &&
          (
            (mapMarker.plotting === "N" && selectedConstructionType["Construction"]) ||
            (mapMarker.plotting === "Y" && selectedConstructionType["Plotting"])
          )
        ) {
          return acc + 1; // Increment if all conditions are true
        }
        return acc; // No change otherwise
      }, 0);
  
      setVisibleSitesCount(count); // Update the state
      dispatch(setVisibleSiteCount(visibleSitesCount))

      
    } else {
      setVisibleSitesCount(0); // Reset if there are no markers
      dispatch(setVisibleSiteCount(visibleSitesCount))
    }
  }, [markerCoordinates, zones.selected, selectedSizes, selectedDeliveryNumbers, siteStatus.selected, selectedClusters, selectedProperty, selectedConstructionType,visibleSitesCount]);

 
  return (
    <ReactMapGL
      {...viewPort}
      onMove={(evt) => setViewport(evt.viewState)}
      className="map-container"
      style={{ height: "100vh" }}
      projection={"globe"}
      reuseMaps
      attributionControl={false}
      mapStyle={changeMap.value}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
    >

      {clustersCoordinates &&
        clustersCoordinates.length > 0 &&
        clustersCoordinates.map(
          (cluster) =>
//            selectedConstructionType['Clusters']
            (
              // <Marker
              //   key={cluster.cluster}
              //   longitude={cluster.Longitude}
              //   latitude={cluster.Latitude}
              //   className={ "selected-map-marker" }
              //   onClick={(evt) => handleClusterClick(evt, cluster)}
              // >
              //   {
              //     <Icon
              //       title={ cluster.num_sites}
              //       className={"fa-solid fa-building-shield"}
              //       fontSize="medium"
              //       style={
              //         {
              //           color: "white",
              //           background: "rgba(0, 0, 0, 0.5)",
              //           padding: "0.5rem",
              //           borderRadius: "50%",
              //           width: "2rem",
              //           height: "1rem",
              //         }
              //       }
              //     />
              //   }
              // </Marker>
                <Marker
                  key={cluster.cluster}
                  longitude={cluster.Longitude}
                  latitude={cluster.Latitude}
                  className={"selected-map-marker"}
                  onClick={(evt) => handleClusterClick(cluster)}
                >
                  <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <Icon
                      title={cluster.cluster}
                      className={"fa-solid fa-building-shield"}
                      fontSize="medium"
                      style={{
                        color: "white",
                        background: "rgba(0, 0, 0, 0.5)",
                        padding: "0.5rem",
                        borderRadius: "50%",
                        width: "2rem",
                        height: "1rem",
                        animation: "colorChange 2s infinite",  // CSS animation for changing colors
                      }}
                    />
                          {/* <img 
                           style={{
                            color: "white",
                            // background: "rgba(0, 0, 0, 0.5)",
                            padding: "0.5rem",
                            borderRadius: "50%",
                            width: "5rem",
                            height: "3rem",
                           
                          }}
                          src={constructiongif1} alt="Your GIF" /> */}
                    {/* Displaying the num_sites below the icon */}
                    <span
                      style={{
                        marginTop: "0.2rem",
                        background: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        padding: "0.2rem 0.5rem",
                        borderRadius: "0.2rem",
                        fontSize: "0.75rem",
                      }}
                    >
                      {cluster.num_sites}
                    </span>
                  </div>
                </Marker>

            )
        )}



      {markerCoordinates &&
        markerCoordinates.length > 0 &&
        markerCoordinates.map(
          (mapMarker) =>
            zones.selected[mapMarker.Zone] &&
            selectedSizes[mapMarker.size] &&
            selectedDeliveryNumbers[mapMarker["Delivery Number"]] &&
            siteStatus.selected[mapMarker.status] &&
            selectedClusters[mapMarker.cluster] &&
            selectedProperty[mapMarker["Land-Property Classification"]] &&
            (
              (mapMarker.plotting === 'N' && selectedConstructionType["Construction"]) || 
              (mapMarker.plotting === 'Y' && selectedConstructionType["Plotting"])
            ) && (
              <Marker
                key={mapMarker.ID}
                longitude={mapMarker.Longitude}
                latitude={mapMarker.Latitude}
                className={
                  selectedMarker[mapMarker.ID] ? "selected-map-marker" : ""
                }
                onClick={(evt) => handleMarkerClick(evt, mapMarker)}
              >
                {siteStatus.dataMap[mapMarker.status].type === "image" ? (
                  <img
                    src={pmayLogo}
                    alt={siteStatus.dataMap[mapMarker.status].label}
                    style={
                      selectedMarker[mapMarker.ID]
                        ? {
                            color: siteStatus.dataMap[mapMarker.status].color,
                            background: "rgba(0, 0, 0, 0.5)",
                            padding: "0.5rem",
                            borderRadius: "50%",
                            width: "2rem",
                            height: "1rem",
                          }
                        : {
                            width: "2rem",
                            height: "1rem",
                          }
                    }
                  />
                ) : (
                  <Icon
                    title={
                      siteStatus.dataMap[mapMarker.status].label ||
                      mapMarker.status
                    }
                    baseClassName="fas"
                    className={
                      mapMarker.status === "not-applicable"
                        ? "fa-circle-minus"
                        : "fa-location-dot"
                    }
                    fontSize="medium"
                    style={
                      selectedMarker[mapMarker.ID]
                        ? {
                            color: siteStatus.dataMap[mapMarker.status].color,
                            background: "rgba(0, 0, 0, 0.5)",
                            padding: "0.5rem",
                            borderRadius: "50%",
                            fontSize: "0.75rem",
                          }
                        : { color: siteStatus.dataMap[mapMarker.status].color, fontSize: "0.75rem" }
                    }
                  />
                )}
              </Marker>
            )
        )}

      {showPopup &&
        siteInfo &&
        siteStatus.selected[siteInfo.status] &&
        zones.selected[siteInfo.Zone] && (
          <Popup
            longitude={siteInfo.Longitude}
            latitude={siteInfo.Latitude}
            offset={10}
            closeOnClick={false}
            closeOnMove={false}
            anchor="left"
            className="custom-map-popup"
          >
            <MarkerPopup
              popupCoordinates={siteInfo}
              siteImage={siteImage}
              setShowPopup={setShowPopup}
            />
          </Popup>
        )}

      {/* Add the zones */}
      {zones.data.map(
        (zone) =>
          zones.selected[zone.Zone] && (
            <ZonePolygon zoneData={zone} layerFill={zone.color} lineWidth={6} dotted={false} />
          )
      )}
      {zones.aois.map(
        (aoi) =>
          (
            <ZonePolygon zoneData={aoi} layerFill={"black"} lineWidth={3} dotted={true} />
          )
      )}
    </ReactMapGL>
  );
}

// const ZonePolygon = ({ zoneData, layerFill }) => {
//   // console.log("zone polygon", layerFill);
//   return (
//     <Source
//       type="geojson"
//       data={{
//         type: "Feature",
//         geometry: JSON.parse(zoneData.geometry),
//       }}
//     >
//       <Layer
//         type="fill"
//         paint={{
//           "fill-color": layerFill,
//           "fill-opacity": 0.3,
//         }}
//       />
//     </Source>
//   );
// };

const ZonePolygon = ({ zoneData, layerFill, lineWidth, dotted }) => {
  return (
    <Source
      type="geojson"
      data={{
        type: "Feature",
        geometry: JSON.parse(zoneData.geometry),
      }}
    >
      <Layer
        type="line"
        paint={{
          "line-color": layerFill,
          "line-width": lineWidth,
          ...(dotted && { "line-dasharray": [2, 2] }),
        }}
      />
    </Source>
  );
};

export default MapContainer;
