import { BarChart, Bar, XAxis, ResponsiveContainer, YAxis, Tooltip as RechartsTooltip, Legend as RechartsLegend } from 'recharts';
import { useEffect, useState } from "react";
import "../Analytics/style.css";
import { MenuItem, Select, FormControl, Checkbox, ListItemText, Box } from "@mui/material";
import { useSelector } from 'react-redux';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

const BarGraph2 = ({ sites, landtypes,Zone }) => {
    
    const zones = useSelector((state) => state.zones.data);
    const selectAllOptions = process.env.REACT_APP_ALL_SELECT_OPTION
    const isZoneChecked = (zone) => selectedZones.includes(zone);


    const [filterData, setFilterData] = useState([]);
    const [selectedZones, setSelectedZones] = useState([]);

    const propertyTypeData = useSelector((state) => state.markerCoordinates.propertyType.slice(1));

    const CustomArrowIcon = () => (
        <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 10L12 15L17 10H7Z" fill="white" />
        </svg>
    );

    const filterDataByZone = () => {
        const landTypeCounts = {};
        const landTypeSet = new Set(propertyTypeData);

        sites.forEach(site => {
            const landType = site['Land-Property Classification'];
            if (selectedZones.length === 0 || selectedZones.includes(site.Zone)) {
                if (landTypeSet.has(landType)) {
                    landTypeCounts[landType] = (landTypeCounts[landType] || 0) + 1;
                }
            }
        });

        const formattedData = Object.entries(landTypeCounts).map(([key, value]) => ({
            name: key,
            count: value
        }));

        setFilterData(formattedData);
    };


    

    const handleZoneChange = (event) => {
        const { value } = event.target;
     if(!value.includes(selectAllOptions) && isZoneChecked(selectAllOptions)){
       setSelectedZones([""]);
     }
     else if(value.includes(selectAllOptions) && !isZoneChecked(selectAllOptions)){
        setSelectedZones(Zone);
    
     }
     else if(value.includes(selectAllOptions) && isZoneChecked(selectAllOptions)){
      setSelectedZones(value);
     }
     else{
      setSelectedZones(value);
     }
  
    
    };

    useEffect(() => {
        if (zones.length > 0) {
            setSelectedZones(zones.map(zone => zone.Zone));
        }
    }, [zones]);

    useEffect(() => {
        filterDataByZone();
    }, [selectedZones, sites]);

    return (
        <div className="main-Tab">
            <div className="heading-part">
                <h1>SITES DETECTED WERE VARIED IN <br /> SIZE AND CLASSIFICATION</h1>
                <div className="pieContainer-divider"></div>

                <div className="dropdown">
                    <FormControl
                        required
                        sx={{ m: 1, minWidth: 160 }}
                        style={{
                            backgroundColor: "#2B3D6C",
                            border: "3px solid black",
                            color: "white",
                            display: "flex",
                            alignItems: "center"  // Align items vertically in the center
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <FilterAltIcon sx={{ color: "white", marginRight: 1 }} />

                            <Select
                                style={{ color: "white" }}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                id="zone-select"
                                multiple
                                value={selectedZones}
                                onChange={handleZoneChange}
                                renderValue={() => 'Zone'}
                                IconComponent={CustomArrowIcon}
                            >
                                {zones.map((zone) => (
                                    <MenuItem key={zone.Zone} value={zone.Zone}>
                                        <Checkbox checked={isZoneChecked(zone.Zone)} />
                                        <ListItemText primary={zone.Zone} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </FormControl>
                </div>
            </div>

            <div className="barGraph">
                <div className="breakdownData">
                    <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={filterData} layout="horizontal">
                            <XAxis
                                dataKey="name"
                                type="category"
                                interval={0}
                                tick={{
                                    fill: 'black',
                                    fontWeight: 'bold',
                                    angle: -25,
                                    fontSize: "8px",
                                    textAnchor: 'end'
                                }}
                            />
                            <YAxis type="number" tick={{ fill: 'black', fontWeight: 'bold' }} />
                            <RechartsTooltip />
                            <RechartsLegend wrapperStyle={{ color: 'black' }} />
                            <Bar barSize={50} dataKey="count" fill="grey" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
};

export default BarGraph2;
