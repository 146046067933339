import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: "",
  styleArray: [],
  loading: false,
  error: null,
  visibleSiteCount: 0
};

const mapViews = createSlice({
  name: "MapView",
  initialState,
  reducers: {
    fetchMapViewStart(state) {
      console.log("Initial State Map View");
      state.loading = true;
      state.error = null;
    },
    fetchMapViewSuccess(state, action) {
      console.log("Map data fetched successfully");
      state.loading = false;
      state.styleArray = action.payload;
      state.value = state.styleArray[0] || "mapbox://styles/mapbox/navigation-night-v1";
   
    },
  


    setMapValue(state, action) {
      state.value = action.payload; 
    },
    setVisibleSiteCount(state,action){
      state.visibleSiteCount = action.payload;
    },
    fetchMapViewFailure(state, action) {
      console.log("Failed to load MapView");
      state.loading = false;
      state.error = action.payload; 
    },
  },
});

export const {
  fetchMapViewStart,
  fetchMapViewSuccess,
  setMapValue,
  fetchMapViewFailure,
  setVisibleSiteCount
} = mapViews.actions;

export default mapViews.reducer;
