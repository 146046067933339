import { Button, Fab, MenuItem, Select, FormControl, CircularProgress, Backdrop, Icon, Checkbox, ListItemText, Box } from "@mui/material";

import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement, Tooltip, Legend, Title, RadialLinearScale } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useEffect, useRef, useState, } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import "../Analytics/style.css";
import { useSelector } from "react-redux";
import varanasi from '../../assets/images/varanasi.jpg';
import jhansi from '../../assets/images/jhansi.jpg';
import BarGraph1 from "./BarGraph1";
import BarGraph2 from "./BarGraph2";

// Register required Chart.js components
Chart.register(ArcElement, Tooltip, Legend, Title, RadialLinearScale);

const CardWithDoughnut = () => {
  const capture_page1_pdf = useRef(null);
  const capture_page2_pdf = useRef(null);
  const capture_page3_pdf = useRef(null);
  const capture_page4_pdf = useRef(null);

  const zones = useSelector((state) => state.zones);

  const selectAllOptions = process.env.REACT_APP_ALL_SELECT_OPTION




  // State variables
  const [site, setSites] = useState([]);
  const [totalSite, setTotalSites] = useState(0);
  const [organisation,setOrganisation] = useState('');
  const [siteCounts, setSiteCounts] = useState({
    white: 0, yellow: 0, green: 0, orange: 0, red: 0,

  });
  const isZoneChecked = (zone) => selectedZone.includes(zone);

  const siteStatus = useSelector((state) => state.markerCoordinates.data);
  const fetchMetaData = useSelector((state) => state.metaData.data.organisation);


  const [openPBar, setPBar] = useState(false);
  const [piedata, setPieData] = useState([]);
  const [Zones, setZones] = useState([]);
  const [Status, setStatus] = useState([]);
  const [size, setSize] = useState([]);
  const [landType, setLandType] = useState([]);
  const [selectedZone, setSelectedZone] = useState([]);
 
  
  const handleZoneChange = (event) => {
    const { value } = event.target;
     if(!value.includes(selectAllOptions) && isZoneChecked(selectAllOptions)){
       setSelectedZone([""]);
     }
     else if(value.includes(selectAllOptions) && !isZoneChecked(selectAllOptions)){
      setSelectedZone(Zones);
     }
     else if(value.includes(selectAllOptions) && isZoneChecked(selectAllOptions)){
      setSelectedZone(value);
     }
     else{
      setSelectedZone(value);
     }
  };

  





  const countSiteforPage1 = (sites, selectedZone) => {
    const newwhite = [];
    const yellow = [];
    const green = [];
    const orange = [];
    const red = [];

    for (let index = 0; index < sites.length; index++) {
      const element = sites[index];

      if (!selectedZone.length || selectedZone.includes(element.Zone)) {
        if (element.status === "new") {
          newwhite.push(element);
        }
        if (["New-Assigned", "ground-inspection", "re-inspection"].includes(element.status)) {
          yellow.push(element);
        }
        if (["map-issued", "pmay", "map-submitted", "govt-campus", "not-applicable"].includes(element.status)) {
          green.push(element);
        }
        if (["notice", "do-issued", "d.o issued"].includes(element.status)) {
          orange.push(element);
        }
        if (["demolished", "sealed", "court-case"].includes(element.status)) {
          red.push(element);
        }
      }
    }

    setPieData([
      { name: "New", value: newwhite.length },
      { name: "Ground / Re - Inspection", value: yellow.length },
      { name: "Notice / D.O", value: orange.length },
      { name: "Map / PMAY / Gov.", value: green.length },
      { name: "Demolish / Sealed / Court.", value: red.length },
    ]);

    setSiteCounts({
      white: newwhite.length,
      yellow: yellow.length,
      green: green.length,
      orange: orange.length,
      red: red.length,
    });

    setTotalSites(
      !selectedZone.length
        ? sites.length
        : newwhite.length + yellow.length + green.length + orange.length + red.length
    );
  };


  const CustomArrowIcon = () => (
    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 10L12 15L17 10H7Z" fill="white" />  {/* Change color by setting fill to white */}
    </svg>
  );

  const getZoneList = (sites) => {


    const zon = zones.data.map((zones) => zones.Zone);
    setZones(zon);
    setSelectedZone(zon);



    const uniqueStatus = sites?.map(site => site.status)
      .filter((value, index, self) => self.indexOf(value) === index) || [];
    setStatus(uniqueStatus);



  }


  useEffect(() => {


    setSites(siteStatus);
    setTotalSites(siteStatus.length);
    getZoneList(siteStatus);
    console.log(siteStatus);


    console.log(fetchMetaData);
  }, [siteStatus,fetchMetaData]);



  useEffect(() => {
    if (site.length > 0) {
      countSiteforPage1(site, selectedZone);
    }
  }, [site, selectedZone]);



  const handleSaveAsPdf = async () => {
    setPBar(true);
    const pdf = new jsPDF('l', 'px', [800, 510]);

    const capturePage = async (ref, isHidden = false) => {
      if (ref.current) {
        // if (isHidden) {
        //     // Temporarily make page1 visible
        //     ref.current.style.display = 'block';
        // }

        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = pdf.internal.pageSize.getWidth();
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        pdf.addPage();

        // if (isHidden) {
        //     // Hide page1 again after capturing it
        //     ref.current.style.display = 'none';
        // }
      }
    };

    await capturePage(capture_page1_pdf, false);


    await capturePage(capture_page2_pdf);
    await capturePage(capture_page3_pdf);
    await capturePage(capture_page4_pdf);

    pdf.deletePage(pdf.internal.getNumberOfPages());

    pdf.save("VDA_Report.pdf");
    setPBar(false);
  };

  return <>

    <div className="rootContainer"> {/*Root Container*/}
      <div className="banner" ref={capture_page1_pdf}>
        <div className="bg-image"
          style={{
            backgroundImage: fetchMetaData === "jhansi"
              ? `url(${jhansi})`
              : fetchMetaData === "varanasi"
                ? `url(${varanasi})`
                : 'none'
          }}
        >
          <div className="saveasPdfbtn">
            <Button variant="outlined" className="savePdf" onClick={handleSaveAsPdf} startIcon={<PictureAsPdfOutlinedIcon />}>
              Save as Pdf
            </Button>
          </div>
        </div>
      </div>
      <div ref={capture_page2_pdf}>
        <div className="pieContainer" >
          <div className="heading-part">
            <h1 >
              We Identified <span>{totalSite}</span> Sites
            </h1>
            <div className="pieContainer-divider"></div>
            <div className="pieContainer-dropdown">

              <FormControl
                required
                sx={{ m: 1, minWidth: 160 }}
                style={{
                  backgroundColor: "#2B3D6C",
                  border: "3px solid black",
                  color: "white",
                  display: "flex",
                  alignItems: "center"  // Align items vertically in the center
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>

                  <FilterAltIcon sx={{ color: "white", marginRight: 1 }} />

                  {/* Select component */}
                  <Select
                    multiple
                    style={{ color: "white" }}
                    id="zone-select"
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    value={selectedZone}
                    onChange={handleZoneChange}
                    renderValue={() => 'Zone'}
                    IconComponent={CustomArrowIcon} // Custom arrow SVG
                  >
                    {Zones.map((zone) => (
                      <MenuItem key={zone} value={zone}>
                        <Checkbox checked={isZoneChecked(zone)} />
                        <ListItemText primary={zone} />
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </FormControl>


            </div>
          </div>

          <div className="pieChart-parent">
            <div className="pieChart">
              <Doughnut

                data={{
                  labels: piedata.map((data) => data.name),
                  datasets: [{
                    label: "Count",
                    data: piedata.map((data) => data.value),
                    backgroundColor: ["rgb(226, 226, 226)", "yellow", "orange", 'green', 'red'],
                    borderWidth: 2,
                  }]
                }}
                options={{
                  plugins: {

                    title: { display: true, text: "Site Status" },
                    legend: {
                      position: 'bottom', labels: {
                        color: "black"
                      }
                    }
                  },
                  layout: {
                    padding: {
                      bottom: 10,
                      top: 10,
                    }
                  },

                  maintainAspectRatio: true,
                }}
              />
            </div>



            <div className="sidecards">
              <div className="upper_two_card">
                <div className="card1" style={{ backgroundColor: "rgb(226, 226, 226)" }}>
                  <div className="card-Data">
                    <div className="fab-button" aria-label="add" >
                      <h3>{siteCounts.white}</h3>
                    </div>
                    <h3 className="card-heading">{"New"}</h3>
                  </div>
                </div>

                <div className="card2" style={{ backgroundColor: "yellow" }}>
                  <div className="card-Data">
                    <div className="fab-button" aria-label="add" >
                      <h3>{siteCounts.yellow}</h3>
                    </div>
                    <h3 className="card-heading">{"New Assigned, Ground Inspection, Re-Inspection"}</h3>
                  </div>
                </div>


              </div>
              <div className="card3" style={{ backgroundColor: "green", }}>
                <div className="card-Data-long">
                  <div className="fab-button" aria-label="add" >
                    <h3>{siteCounts.green}</h3>
                  </div>
                  <h3 className="card-heading" style={{ color: "white", }}>{"Map issued, Map Submitted, N.A., PMAY, Govt. Campus"}</h3>
                </div>
              </div>

              <div className="upper_two_card">
                <div className="card4" style={{ backgroundColor: "orange" }}>
                  <div className="card-Data">
                    <div className="fab-button" aria-label="add" >
                      <h3>{siteCounts.orange}</h3>
                    </div>
                    <h3 className="card-heading" style={{ color: "white", }}>{"Notice, D.O-Issued"}</h3>
                  </div>
                </div>

                <div className="card5" style={{ backgroundColor: "red" }}>
                  <div className="card-Data">
                    <div className="fab-button" aria-label="add" >
                      <h3>{siteCounts.red}</h3>
                    </div>
                    <h3 className="card-heading" style={{ color: "white", }}>{"Demolished, Sealed, Court-Case"}</h3>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
        <div className="divider"></div>
      </div>



      <div ref={capture_page3_pdf}>
        <BarGraph1 siteData={site} Zones={Zones} Status={Status} Sizes={size}></BarGraph1>
        <div className="divider"></div>
      </div>



      <div ref={capture_page4_pdf}>
        <BarGraph2 sites={site} landtypes={landType} Zone={Zones}></BarGraph2>
        <div className="divider"></div>
      </div>

    </div>
  </>
}
export default CardWithDoughnut;