import React, { useState, useEffect, useRef } from "react";
import { Icon, Box, TextField, Button, Checkbox } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import Translate from "../Translate/Translate";

import "./MapTopbar.css";
import { fetchSiteidSuccess } from "../../redux/reducers/searchSiteid";
import { selectZones } from "../../redux/reducers/zones";

import { selectSiteStatus } from "../../redux/reducers/siteStatus";
import {
  selectDeliveryNumber,
  selectSiteSize,
  selectConstructionType,
  selectConstructionCluster,
  selectConstructionPropertyType,
} from "../../redux/reducers/mapMarkers";
import helperFunctions from "../../utils/helper";

import pmayLogo from "../../assets/images/pmay-logo.png";

function MapTopbar() {
  const zones = useSelector((state) => state.zones);
  const selectAllOptions = process.env.REACT_APP_ALL_SELECT_OPTION

  const siteStatus = useSelector((state) => state.siteStatus);
  const sizes = useSelector((state) => state.markerCoordinates.sizes);
  const selectedSizes = useSelector(
    (state) => state.markerCoordinates.selectedSizes
  );
  const deliveryNumbers = useSelector(
    (state) => state.markerCoordinates.deliveryNumbers
  );
  const selectedDeliveryNumbers = useSelector(
    (state) => state.markerCoordinates.selectedDeliveryNumbers
  );
  const constructionType = useSelector((state) => state.markerCoordinates.type)
  const selectedConstructionType = useSelector((state) => state.markerCoordinates.selectedType)
  const selectedClusters = useSelector((state) => state.markerCoordinates.selectedCluster)
  const property = useSelector((state) => state.markerCoordinates.propertyType)
  const selectedProperty = useSelector((state) => state.markerCoordinates.selectedPropertyType)


  const user = useSelector((state) => state.user);

  const [showFilters, setShowFilters] = useState({
    status: false,
    size: false,
    deliveryNumber: false,
    type: false,
    propertyType: false,
    zones: false,
  });

  const dispatch = useDispatch();

  const handleChange = (evt) => {
    console.log("searched site ID is ", evt.target.value)
    dispatch(fetchSiteidSuccess(evt.target.value));
  };

  const handleZoneClick = (zone) => {
    if (zone === selectAllOptions) {

      const newStatus = zones.selected[selectAllOptions];
      const undefinedRemoved = Object.fromEntries(
        Object.entries(zones.selected).filter(([key]) => key)
      );

      const toggledSelected = Object.fromEntries(
        Object.entries(undefinedRemoved).map(([key, value]) => [key, !newStatus])
      );
      dispatch(selectZones(toggledSelected));

    }
    else {
      const tempObj = {};
      tempObj[zone] = true;

      if (zones.selected[zone] === null || zones.selected[zone] === true) {
        tempObj[zone] = false;
      }

      dispatch(selectZones(tempObj));
    }
  };


  const handleStatusCheckboxClick = (checked, statusType) => {
    if (statusType === selectAllOptions) {
      const newStatus = !siteStatus.selected[selectAllOptions];
      const selectObj = siteStatus.data.reduce((obj, { value }) => {
        obj[value] = newStatus;
        return obj;
      }, {});

      dispatch(selectSiteStatus(selectObj));

    }
    else {
      const statusSelectObj = {};
      statusSelectObj[statusType] = !checked;
      dispatch(selectSiteStatus(statusSelectObj));
    }
  };

  const handleSiteSizeCheckboxClick = (checked, size) => {
    if (size === selectAllOptions) {
      const changedValues = helperFunctions.selectAll(selectedSizes, sizes, selectAllOptions);
      dispatch(selectSiteSize(changedValues));

    }
    else {
      const sizeSelectObj = {};
      sizeSelectObj[size] = !checked;
      console.log("sizeSelectObj[size] ", sizeSelectObj[size], size)
      dispatch(selectSiteSize(sizeSelectObj));
    }
  };

  const handleDeliveryNumberCheckboxClick = (checked, deliveryNumber) => {
    if (deliveryNumber === selectAllOptions) {
      const changedValues = helperFunctions.selectAll(selectedDeliveryNumbers, deliveryNumbers, selectAllOptions);
      dispatch(selectDeliveryNumber(changedValues));
    }
    else {
      const deliveryNumberSelectObj = {};
      deliveryNumberSelectObj[deliveryNumber] = !checked;
      dispatch(selectDeliveryNumber(deliveryNumberSelectObj));
    }
  };


  const handleConstructionTypeCheckboxClick = (checked, type) => {
    if (type === selectAllOptions) {
      const changedValues = helperFunctions.selectAll(selectedConstructionType, constructionType, selectAllOptions);
      dispatch(selectConstructionType(changedValues));
    }
    else {
      const typeSelectedObj = {};
      typeSelectedObj[type] = !checked;
      console.log("typeSelectedObj[type] ", typeSelectedObj[type], type)

      dispatch(selectConstructionType(typeSelectedObj))
    }
  }

  const handlePropertyTypeCheckboxClick = (checked, type) => {
    if (type === selectAllOptions) {
      const changedValues = helperFunctions.selectAll(selectedProperty, property, selectAllOptions);
      dispatch(selectConstructionPropertyType(changedValues));
    }
    else {
      const typeSelectedObj = {};
      typeSelectedObj[type] = !checked;
      console.log("Property type ", typeSelectedObj[type], type)

      dispatch(selectConstructionPropertyType(typeSelectedObj))
    }
  }
  const toggleDropdown = (dropdown) => {
    console.log("toggleDropdown ", dropdown)
    setShowFilters({
      ...showFilters,
      status: false,
      size: false,
      deliveryNumber: false,
      type: false,
      propertyType: false,
      [dropdown]: !showFilters[dropdown],
    });
  };

  const dropdownRefs = useRef([]);

  const handleCloseDropdown = (dropdown, index) => {
    return (e) => {
      if (dropdownRefs.current[index].contains(e.relatedTarget)) {
        return;
      }

      setShowFilters({
        ...showFilters,
        [dropdown]: false,
      });
    };
  };

  return (
    <div className="topbar-container">
      <div className="topbar-sub-container topbar-sub-container-left">
        <Box className="box-container box-search">
          <Icon
            title="Search"
            baseClassName="fas"
            className="fa-regular fa-magnifying-glass container-icon"
            fontSize="small"
          />
          <TextField
            id="input-with-sx"
            placeholder={
              user.language !== "hi"
                ? "Site ID"
                : "साइट आईडी से खोजें"
            }
            variant="standard"
            onChange={handleChange}
          />
        </Box>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("status", 0)}
          ref={(el) => (dropdownRefs.current[0] = el)} // Set a ref to the first dropdown
          tabIndex={11}
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("status")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "8rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Status" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.status && (
            <div className="status-select-dropdown">
              {siteStatus.data.map((statusObj) => {
                return (
                  <div
                    key={"status-" + statusObj.value}
                    className="site-info-status-select-sub-container cursor-pointer"
                    style={{ color: statusObj.color }}
                    onClick={() => {
                      handleStatusCheckboxClick(
                        siteStatus.selected[statusObj.value],
                        statusObj.value
                      );
                    }}
                  >
                    <Checkbox
                      onChange={(evt) =>
                        handleStatusCheckboxClick(
                          siteStatus.selected[statusObj.value],
                          statusObj.value
                        )
                      }
                      checked={siteStatus.selected[statusObj.value] === true}
                    />

                    {statusObj.type === "image" ? (
                      <img
                        src={pmayLogo}
                        alt={statusObj.label}
                        style={{
                          width: "3rem",
                          height: "1.5rem",
                        }}
                      />
                    ) : (
                      <Icon
                        title={statusObj.label}
                        baseClassName={statusObj.iconBaseClass || "fas"}
                        className={"cursor-pointer " + statusObj.icon}
                        fontSize="small"
                      />
                    )}

                    <p className="m-0" style={{ flexGrow: 1 }}>
                      <Translate text={statusObj.label} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("size", 1)}
          ref={(el) => (dropdownRefs.current[1] = el)} // Set a ref to the second dropdown
          tabIndex={12}
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("size")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "8rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Size" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.size && (
            <div className="status-select-dropdown">
              {sizes.map((size) => {
                return (
                  <div
                    key={"size-" + size}
                    className="site-info-status-select-sub-container cursor-pointer"
                    onClick={() => {
                      handleSiteSizeCheckboxClick(selectedSizes[size], size);
                    }}
                  >
                    <Checkbox
                      onChange={() =>
                        handleSiteSizeCheckboxClick(selectedSizes[size], size)
                      }
                      checked={selectedSizes[size] === true}
                    />

                    <p className="m-0" style={{ flexGrow: 1, fontSize: "0.8rem" }}>
                      <Translate text={size} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("type", 3)}
          ref={(el) => (dropdownRefs.current[3] = el)} // Set a ref to the third dropdown
          tabIndex={14}
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("type")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "8rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Type" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.type && (
            <div className="status-select-dropdown">
              {constructionType.map((type) => {
                return (
                  <div
                    key={"type-" + type}
                    className="site-info-status-select-sub-container cursor-pointer"
                    onClick={() => {
                      handleConstructionTypeCheckboxClick(selectedConstructionType[type], type);
                    }}
                  >
                    <Checkbox
                      onChange={() =>
                        handleConstructionTypeCheckboxClick(selectedConstructionType[type], type)
                      }
                      checked={selectedConstructionType[type] === true}
                    />

                    <p className="m-0" style={{ flexGrow: 1, fontSize: "0.8rem" }}>
                      <Translate text={type} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("deliveryNumber", 2)}
          ref={(el) => (dropdownRefs.current[2] = el)} // Set a ref to the second dropdown
          tabIndex={13}
          translate="yes"
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("deliveryNumber")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "8rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Delivery" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.deliveryNumber && (
            <div
              className="status-select-dropdown"
              style={{
                maxHeight: "8rem",
                overflowY: "scroll",
                width: "12rem",
              }}
            >
              {deliveryNumbers.map((deliveryNumber) => {
                return (
                  <div
                    key={"deliveryNumber-" + deliveryNumber}
                    className="site-info-status-select-sub-container cursor-pointer"
                    onClick={() => {
                      handleDeliveryNumberCheckboxClick(
                        selectedDeliveryNumbers[deliveryNumber],
                        deliveryNumber
                      );
                    }}
                  >
                    <Checkbox
                      onChange={() =>
                        handleDeliveryNumberCheckboxClick(
                          selectedDeliveryNumbers[deliveryNumber],
                          deliveryNumber
                        )
                      }
                      checked={selectedDeliveryNumbers[deliveryNumber] === true}
                    />

                    <p className="m-0" style={{ flexGrow: 1, fontSize: "0.8rem" }}>
                      <Translate text={deliveryNumber || "-- Not Delivered --"} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div
          className="filters-dropdown-container-box"
          onBlur={handleCloseDropdown("propertyType", 4)}
          ref={(el) => (dropdownRefs.current[4] = el)} // Set a ref to the fourth dropdown
          tabIndex={15}
        >
          <Button
            onClick={() =>
              // hide other dropdowns
              toggleDropdown("propertyType")
            }
            variant="contained"
            style={{
              borderRadius: "0.75rem",
              background: "var(--bg-color)",
              color: "var(--text-color)",
              padding: "1rem",
              width: "8rem",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="filters-button"
          >
            <Icon
              title="Filter"
              baseClassName="fas"
              className="fa-regular fa-filter container-icon"
              fontSize="small"
            />

            <p className="filters-button-p">
              <Translate text="Property" />
            </p>

            <Icon
              title="Expand"
              baseClassName="fas"
              className="fa-regular fa-caret-down"
              fontSize="small"
            />
          </Button>

          {showFilters.propertyType && (
            <div className="status-select-dropdown">
              {property.map((type) => {
                return (
                  <div
                    key={"property-" + type}
                    className="site-info-status-select-sub-container cursor-pointer"
                    onClick={() => {
                      handlePropertyTypeCheckboxClick(selectedProperty[type], type);
                    }}
                  >
                    <Checkbox
                      onChange={() =>
                        handlePropertyTypeCheckboxClick(selectedProperty[type], type)
                      }
                      checked={selectedProperty[type] === true}
                    />

                    <p className="m-0" style={{ flexGrow: 1, fontSize: "0.8rem" }}>
                      <Translate text={type} />
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        {zones.zoneDropDownOnExplorePage && (
          <div
            className="filters-dropdown-container-box"
            onBlur={handleCloseDropdown("zones", 5)}
            ref={(el) => (dropdownRefs.current[5] = el)} // Set a ref to the first dropdown
            tabIndex={16}
          >
            <Button
              onClick={() =>
                // hide other dropdowns
                toggleDropdown("zones")
              }
              variant="contained"
              style={{
                borderRadius: "0.75rem",
                background: "var(--bg-color)",
                color: "var(--text-color)",
                padding: "1rem",
                width: "8rem",
                display: "flex",
                justifyContent: "space-between",
              }}
              className="filters-button"
            >
              <Icon
                title="Filter"
                baseClassName="fas"
                className="fa-regular fa-filter container-icon"
                fontSize="small"
              />

              <p className="filters-button-p">
                <Translate text="Zones" />
              </p>

              <Icon
                title="Expand"
                baseClassName="fas"
                className="fa-regular fa-caret-down"
                fontSize="small"
              />
            </Button>

            {showFilters.zones && (
              <div className="status-select-dropdown">
                {zones.data.map((zone) => {
                  return (
                    <div
                      key={"zone-" + zone.Zone}
                      className="site-info-status-select-sub-container cursor-pointer"
                      style={{ color: zone.color }}
                      onClick={() => {
                        handleZoneClick(zone.Zone)
                      }}
                    >
                      <Checkbox
                        onChange={(evt) =>
                          handleZoneClick(zone.Zone)
                        }
                        checked={zones.selected[zone.Zone] === true}
                      />

                      <p className="m-0" style={{ flexGrow: 1 }}>
                        <Translate text={zone.Zone} />
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}


        {!zones.zoneDropDownOnExplorePage && zones.data.slice(1).map((zone) => (
          <Button
            key={"zone-" + zone.Zone}
            variant="contained"
            onClick={() => handleZoneClick(zone.Zone)}
            style={{
              background: zone.color,
              border: "1px solid var(--border-color)",
              fontWeight: "bold",
              borderRadius: "8px",
              fontSize: "0.8rem"
            }}
          >
            {zones.selected[zone.Zone] === true && (
              <Icon
                title="Selected"
                baseClassName="fas"
                className="fa-regular fa-check container-icon"
                fontSize="small"
              />
            )}
            <Translate text={zone.Zone} />
          </Button>
        ))}
      </div>
    </div>
  );
}


export default MapTopbar;
